import { createRouter, createWebHashHistory } from 'vue-router'
import Home from '../views/Home.vue'
import Viewer from '../views/Viewer.vue'
import PaymentOutcome from '../views/PaymentOutcome.vue'
import Calendar from '../views/Calendar.vue'
import QimenPredict from '../views/QimenPredict.vue'
import QimenHourly from '../views/QimenHourly.vue'
import QimenStrategy from '../views/QimenStrategy.vue'
import QimenFormation from '../views/QimenFormation.vue'
import QimenDay from "../views/QimenDay.vue"

// const routes = [
//   {
//     path: '/',
//     name: 'Home',
//     component: Home
//   },
//   {
//     path: '/about',
//     name: 'About',
//     // route level code-splitting
//     // this generates a separate chunk (about.[hash].js) for this route
//     // which is lazy-loaded when the route is visited.
//     component: () => import(/* webpackChunkName: "about" */ '../views/About.vue')
//   }
// ]

const routes = [
  {
    path: '/',
    name: 'Home',
    component: Home
  },
  {
    path: '/view/:email/:key',
    name: 'Viewer',
    component: Viewer
  },
  {
    path: '/success/:orderId',
    name: 'Success',
    component: PaymentOutcome
  },
  {
    path: '/fail/:orderId',
    name: 'Fail',
    component: PaymentOutcome
  },
  {
    path: '/calendar/:email/:key?',
    name: 'Calendar',
    component: Calendar
  },
  {
    path: '/qimen/predict',
    name: 'QimenPredict',
    component: QimenPredict
  },
  {
    path: '/qimen/hourly',
    name: 'QimenHourly',
    component: QimenHourly
  },
  {
    path: '/qimen/day',
    name: 'QimenDay',
    component: QimenDay
  },
  {
    path: '/qimen/strategy/:action?',
    name: 'QimenStrategy',
    component: QimenStrategy
  },
  {
    path: '/qimen/formation/:action?',
    name: 'QimenFormation',
    component: QimenFormation
  },
]

const router = createRouter({
  history: createWebHashHistory(),
  routes
})

export default router
